import {useEffect, useRef, useState} from "react";
import styles from "./app.module.css";
import {useChatCompletion} from "openai-streaming-hooks";

export default function Home() {
    const [input, setInput] = useState("");
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false)
    const [triggerValue, setTriggerValue] = useState(null)
    const [streamingResponse, setStreamingResponse] = useState('');
    const [streamingAnswer, setStreamingAnswer] = useState('');
    const myRef = useRef(null);
    const [replyDeltas, setReplyDeltas] = useState([]);
    const [currentReplyIndex, setCurrentReplyIndex] = useState(0);
    const [activeStream, setActiveStream] = useState(false);
    const [value, setvalue] = useState('')
    const { messages, submitPrompt } = useChatCompletion({
        model: 'gpt-3.5-turbo',
        apiKey: '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',
        temperature: 0.9,
    });
    const [promptText, setPromptText] = useState('hello');

    const onSend = (input) => {
        submitPrompt([{ content: input, role: 'user' }]);
    };

    // When content is added to the chat window, make sure we scroll to the bottom so the most
    // recent content is visible to the user.
    useEffect(() => {
        if (messages.length > 0) {
            console.log(messages);
            const replyDeltas = [...messages[messages.length - 1]['content']];
            setReplyDeltas(replyDeltas);
        }
    }, [messages]);

    async function onSubmit(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            await updateText();
        }
    }

    const scrollDown = () => {
        //myRef.current.scrollIntoView();
        myRef.current.scrollIntoView({ behavior: "smooth" });
    }

    async function replaceDynamicData(unprocessedReply) {
        const pattern = /[^{\}]+(?=})/g;
        const dynamicData = unprocessedReply.match(pattern);
        const endpoints = {
            invoiceProcessingTime: '/invoiceProcessingTime.json'
        }
        if (dynamicData) {
            const texts = await Promise.all(dynamicData.map(async item => {
                const varName = item.substr(4);
                if (endpoints[varName]) {
                    const response = await fetch(endpoints[varName]);
                    const data = await response.json();
                    return data
                }
            }));
            texts.forEach((text, index) => {
                unprocessedReply = unprocessedReply.replace('{{' + dynamicData[index] + '}}', text)
            })
        }

        return unprocessedReply;
    }

    function compare(a, b) {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        return 0;
    }

    function compareVectorsCosine(A, B) {
        var dotproduct = 0;
        var mA = 0;
        var mB = 0;
        for (let i = 0; i < A.length; i++) { // here you missed the i++
            dotproduct += (A[i] * B[i]);
            mA += (A[i] * A[i]);
            mB += (B[i] * B[i]);
        }
        mA = Math.sqrt(mA);
        mB = Math.sqrt(mB);
        var similarity = (dotproduct) / ((mA) * (mB)) // here you needed extra brackets
        console.log(similarity);
        return similarity;
    }

    async function getData() {
        const response = await fetch('/acceptatie-input.json');
        const data = await response.json();
        return data;
    }

    async function getTopResults(vector) {
        //console.log('gettting', vector);
        // get the data
        const response = await fetch('https://weisscher-cognitive-search.search.windows.net/indexes/azure-cognitive-search-vector-obvion/docs/search?api-version=2023-07-01-Preview', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "api-key": "ax9RsjdDyhihNwu5Pmq78r0sUCiIhGlSn2VA09vfuWAzSeA4Tme8"
            },
            body: JSON.stringify({
                "vector": {
                    "value": vector,
                    "fields": "contentVector",
                    "k": 8
                },
                "select": "title, content, category"
            }),
        });
        const data = await response.json();
        let texts = '';
        console.log(data);

        for (let i = 0; i < data.value.length; i++) {
            texts = texts + data.value[i].content + '\n'
        }

        return texts
    }

    async function getEmbedding(text) {
        const embedding = await fetch("https://openai-weisscher.openai.azure.com/openai/deployments/text-embedding-ada-002/embeddings?api-version=2023-05-15", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "api-key": "7c067c61b10a4067885aaa5e657c7803"
            },
            body: JSON.stringify({input: text}),
        });
        return await embedding.json();
    }

    function generatePrompt(prompt, context) {
        return {
            model: "gpt-3.5-turbo",
            temperature: 0,
            messages: [
                {
                    "role": "system",
                    "content": context
                },
                {
                    "role": "user",
                    "content": prompt + '\n\n Answer in English, never provide a Dutch translation.'
                },
            ]
        }
    }

    async function updateText() {
        console.log('updatetext');
        setInput("");
        setStreamingResponse("");
        setLoading(true);
        let chat = result.slice();
        chat.push(<div className={styles.question} key={Math.random()}>{input}</div>);
        setResult(chat);

        console.log('### start embedding ###');
        const embedding = await getEmbedding(input);
        console.log(embedding);
        const vector = embedding.data[0].embedding;
        console.log(vector);
        console.log('### comparing vectors ###')
        const topResults = await getTopResults(vector);
        console.log('### compose request and get response ###')

        onSend(input);
    }

    useEffect(() => {
        window.addEventListener('keyup', onSubmit)
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keyup', onSubmit)
        }
    }, [onSubmit])

    useEffect(() => {
        console.log(replyDeltas);
        setTimeout(() => {
            if (replyDeltas[currentReplyIndex]) {
                const tempString = streamingAnswer + replyDeltas[currentReplyIndex];
                tempString.replace('\n', '<br>');
                setStreamingAnswer(tempString);
            }
            if (currentReplyIndex > 0 && currentReplyIndex === replyDeltas.length) {
                setReplyDeltas([])
                setCurrentReplyIndex(0)
                const replyComponent = (<div className={styles.answer} key={Math.random()}>
                    <div className={styles.name}>
                        Assistent
                    </div>
                    <div dangerouslySetInnerHTML={{__html: streamingAnswer}} style={{whiteSpace: "pre-wrap"}}></div>
                </div>);
                setResult(result => [...result, replyComponent]);
                setLoading(false);
                setStreamingAnswer('');
            }
        }, 10)

    }, [replyDeltas, currentReplyIndex])

    useEffect(() => {
        //console.log(streamingAnswer);
        if (currentReplyIndex < replyDeltas.length) {
            setCurrentReplyIndex(currentReplyIndex + 1);
        }
    }, [streamingAnswer])

    useEffect(() => {
        scrollDown();
    }, [result, streamingAnswer])

    useEffect(() => {
        if (triggerValue !== null && triggerValue === input) {
            setTriggerValue(null);
            updateText();
        }
    }, [input, triggerValue])

    async function compileEmbeddings() {
        const data = await getData();
        const vectors = []
        await Promise.all(
            data.map(async (text, index) => {
                return setTimeout(async () => {
                    const vector = await getEmbedding(text);

                    vectors.push({
                        "id": "" + (index + 1),
                        "content": text,
                        "category": "acceptatiebeleid",
                        "contentVector": vector.data[0].embedding,
                        "@search.action": "upload"
                    })
                    console.log(vector, vectors);
                }, index * 100);
            })
        )
        return vectors;
    }

    const showSample = (id) => {
        if (id === 'button') {
            setTriggerValue('Kan ik de looptijd van mijn bouwdepot verlengen?')
            setInput('Kan ik de looptijd van mijn bouwdepot verlengen?')
        } else if (id === 'link') {
            setTriggerValue('Hoe declareer ik een factuur?')
            setInput('Hoe declareer ik een factuur?')
        } else if (id === 'api') {
            setTriggerValue('Hoe lang duurt het voordat een factuur is betaald?')
            setInput('Hoe lang duurt het voordat een factuur is betaald?')
        } else if (id === 'list') {
            setTriggerValue('Wat mag ik betalen van het duurzaamheidsdepot?')
            setInput('Wat mag ik betalen van het duurzaamheidsdepot?')
        }
    }

    return (
        <div>
            <main className={styles.main}>
                <div className={styles.result}>
                    <div className={styles.answer}>
                        <div>
                            <div className={styles.name}>
                                Assistent
                            </div>
                            <div>I know all about acceptance criteria for new mortgages. How can I help?
                            </div>
                        </div>
                    </div>
                    <div className={styles.result}>
                        {result}
                        {(streamingAnswer.length > 0 || loading) && <div className={styles.answer}>
                            <div className={styles.name}>Assistent</div>
                            <div dangerouslySetInnerHTML={{__html: streamingAnswer}}
                                 style={{whiteSpace: "pre-wrap"}}></div>
                            {loading && streamingAnswer.length === 0 && <div>
                                <div className="chat-bubble">
                                    <div className="typing">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>}
                        </div>}
                        <div className={styles.newQuestion}><input
                            type="text"
                            name="animal"
                            placeholder="What do you want to know?"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        /></div>
                    </div>
                    <div ref={myRef}></div>
                </div>
            </main>
        </div>
    );
}
